export const colors = {
  primary: "#0300E6", // deep blue color
  secondary: "#1085AF", // turquoise color
  disabled: "#B0B0B0", // lightish gray
  success: "#17A221", // that green color
  error: "#AA0000", // red color
  warning: "#CC5500", // orange color
  borders: "#E0E0E0", // light gray color
  whatsapp: "#25D366", // whatsapp green color
  //TODO: add more colors as needed
};
